import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes, { arrayOf } from 'prop-types';
import shortid from 'shortid';

// Components
import LayoutPage from '../components/Layouts/LayoutPage';
import LoadMoreButton from '../components/Common/Button/LoadMoreButton';
import NewsItem from '../components/News/NewsItem';
import NewsList from '../components/News/NewsList';
import Section from '../components/Common/Section/Section';

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "news" }) {
      wordpress_id
      title
    }
  }
`;

const News = ({ pageContext }) => {
  const { postData, pageData } = pageContext;
  // Set the number of inital posts to display
  const [postsToShow, setPostsToShow] = useState(6);

  function handleLoadMore() {
    setPostsToShow(postsToShow + 6);
  }

  return (
    <LayoutPage
      backgroundImage={pageData.acf.page_hero_bg}
      title={pageData.title.rendered}
      yoastMeta={pageData.yoast_meta}
    >
      <main>
        <Section backgroundColor="#E6ECEF">
          <NewsList>
            {postData.slice(0, postsToShow).map((item) => {
              return (
                <NewsItem
                  baseSlug="news"
                  categories={item._embedded['wp:term'][0]}
                  date={item.date}
                  image={item.acf.image}
                  intro={item.acf.intro}
                  key={shortid.generate()}
                  slug={`/news/${item.slug}`}
                  taxSlug="category"
                  title={item.title.rendered}
                />
              );
            })}
          </NewsList>

          {postsToShow <= postData.length && (
            <LoadMoreButton onClick={handleLoadMore}>Load more</LoadMoreButton>
          )}
        </Section>
      </main>
    </LayoutPage>
  );
};

export default News;

News.defaultProps = {
  pageContext: {
    restData: {
      acf: {
        page_image_slider_images: null,
        page_image_slider: false,
        content_modules: null,
      },
    },
  },
};

News.propTypes = {
  pageContext: PropTypes.shape({
    pageData: PropTypes.shape({
      acf: PropTypes.shape({
        content_modules: PropTypes.arrayOf(PropTypes.shape({})),
        page_hero_bg: PropTypes.shape({}).isRequired,
        page_image_slider: PropTypes.bool,
        page_image_slider_images: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
      title: PropTypes.shape({
        rendered: PropTypes.string.isRequired,
      }),
      yoast_meta: PropTypes.shape({}).isRequired,
    }).isRequired,

    postData: arrayOf(PropTypes.shape({})).isRequired,
  }),
};
